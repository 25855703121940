<template>
    <v-dialog max-width="800" persistent v-model="dialog">
        <v-card>
            <v-toolbar color="primary" dark>
                <v-card-title>
                    <span>{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                            " " +
                            $t("death.deathAnnouncement")
                    }}</span>
                </v-card-title>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="editedItem.employeeGuid"
                                    :loading="loading"
                                    :items="employees"
                                    outlined
                                    hide-details
                                    @input.native="handleChange"
                                    :rules="rules"
                                    item-value="employeeGuid"
                                    item-text="employeeName"
                                    dense
                                    :label="$t('employees.employee')"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="editedItem.content"
                                    outlined
                                    hide-details
                                    :rules="rules"
                                    rows="3"
                                    :label="$t('death.content')"
                                >
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            v-model="editedItem.deathDate"
                                            hide-details
                                            :rules="rules"
                                            dense
                                            :label="$t('death.deathDate')"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="editedItem.deathDate"
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="save" color="primary">{{ $t("save") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog" color="red">{{
                    $t("cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import EmployeesApi from "../../Api/EmployeesApi";
import DeathAnnouncementsApi from "../../Api/DeathAnnouncementsApi";

export default {
    props: [
        "editedItem",
        "editedIndex",
        "dialog",
        "closeDialog",
        "refreshTable"
    ],
    data() {
        return {
            employees: [],
            valid: true,
            menu: false,
            loading: false,
            rules: [value => !!value || this.$t("required")]
        };
    },
    mounted() {
        this.addDefaultEmployee(this.editedItem);
    },
    methods: {
        addDefaultEmployee(item) {
            if (item.employeeGuid != undefined) {
                var employee = {
                    employeeGuid: item.employeeGuid,
                    employeeName: item.employeeName
                };

                this.employees.push(employee);
            }
        },
        handleChange(e) {
           
            var employeeName = e.target.value;
            if (employeeName.length > 2)
            {
                this.loading=true;
                 EmployeesApi.Search(employeeName).then(response => {
                    this.loading=false;
                    if (
                        response.data.data != null &&
                        response.data.data.length > 0
                    )
                        this.employees = response.data.data;
                });
            }
               
        },
        save() {
            try {
                if (!this.$refs.form.validate()) return;

                if (this.editedIndex == -1) {
                    DeathAnnouncementsApi.Create(this.editedItem).then(
                        response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.refreshTable();
                                this.closeDialog();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        }
                    );
                } else {
                    DeathAnnouncementsApi.Update(this.editedItem).then(
                        response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.refreshTable();
                                this.closeDialog();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        }
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
