var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('death.deathAnnouncements'),"icon":"mdi-account-injury-outline"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.isInRole(_vm.roles.deathAnnouncements.create))?_c('v-btn',{attrs:{"color":"primary darken-1"},nativeOn:{"click":function($event){return _vm.newItem($event)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1):_vm._e()]},proxy:true}])}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.deathAnnouncements,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}}),_c('Announcement-Dialog',{key:_vm.dialog,attrs:{"dialog":_vm.dialog,"editedIndex":_vm.editedIndex,"editedItem":_vm.editedItem,"closeDialog":_vm.closeDialog,"refreshTable":_vm.getData}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(
                                    _vm.isInRole(
                                        _vm.roles.deathAnnouncements.update
                                    )
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]):_vm._e()],1),_c('v-col',[(
                                    _vm.isInRole(
                                        _vm.roles.deathAnnouncements.delete
                                    )
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading,"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])]):_vm._e()],1)],1)]}},{key:"item.deathDate",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.deathDate,"yyyy-MM-DD"))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }