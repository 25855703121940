import axios from "axios";

export default class DeathAnnouncementsApi {
    static Get(id = null) {
        if (id != null)
            return axios
                .get("DeathAnnouncements/Get?id=" + id)
                .then(response => response);
        return axios.get("DeathAnnouncements/Get").then(response => response);
    }

    static Create = alert => {
        return axios
            .post("DeathAnnouncements/Create", alert)
            .then(response => response);
    };

    static Update(data) {
        return axios
            .put("DeathAnnouncements/Update", data)
            .then(response => response);
    }

    static Delete = guid => {
        return axios
            .delete("DeathAnnouncements/Delete?id=" + guid)
            .then(response => response);
    };
}
