<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('death.deathAnnouncements')"
                icon="mdi-account-injury-outline"
            >
                <template v-slot:buttons>
                    <v-btn v-if="isInRole(roles.deathAnnouncements.create)" color="primary darken-1" @click.native="newItem">
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="deathAnnouncements"
                    :search="search"
                    :loading="loading"
                    :loading-text="$t('loading')"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('search')"
                                hide-details
                                outlined
                                dense
                            ></v-text-field>
                            <v-spacer></v-spacer>
                            <confirm-dialog
                                :openDialog="dialogDelete"
                                :onClicked="deleteItemConfirm"
                                :onClose="closeDelete"
                                toolBarColor="red darken-2"
                            ></confirm-dialog>
                            <Announcement-Dialog
                                :key="dialog"
                                :dialog="dialog"
                                :editedIndex="editedIndex"
                                :editedItem="editedItem"
                                :closeDialog="closeDialog"
                                :refreshTable="getData"
                            />
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters>
                            <v-col
                                ><v-tooltip
                                    top
                                    v-if="
                                        isInRole(
                                            roles.deathAnnouncements.update
                                        )
                                    "
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            @click="editItem(item)"
                                            v-bind="attrs"
                                            :loading="loading"
                                            v-on="on"
                                        >
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t("edit") }}</span>
                                </v-tooltip></v-col
                            >
                            <v-col
                                ><v-tooltip
                                    top
                                    v-if="
                                        isInRole(
                                            roles.deathAnnouncements.delete
                                        )
                                    "
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            @click="deleteItem(item)"
                                            v-bind="attrs"
                                            :loading="loading"
                                            color="red"
                                            v-on="on"
                                        >
                                            <v-icon>
                                                mdi-delete-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t("delete") }}</span>
                                </v-tooltip></v-col
                            >
                        </v-row>
                    </template>
                    <template v-slot:[`item.deathDate`]="{ item }">
                        {{ item.deathDate | moment("yyyy-MM-DD") }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import PageHeader from "../../components/PageHeader.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import DeathAnnouncementsApi from "../../Api/DeathAnnouncementsApi";
import AnnouncementDialog from "./AnnouncementDialog.vue";
import moment from "moment";

export default {
    components: { PageHeader, ConfirmDialog, AnnouncementDialog },
    created() {
        this.getData();
    },
    data() {
        return {
            editedItem: {},
            defaultItem: {
                content: "",
                deathDate: moment(moment()).format("YYYY-MM-DD")
            },
            editedIndex: -1,
            search: " ",
            dialogDelete: false,
            deathAnnouncements: [],
            dialog: false,
            loading: false,
            headers: [
                {
                    text: this.$t("employees.employeeName"),
                    value: "employeeName"
                },
                { text: this.$t("death.content"), value: "content" },
                { text: this.$t("death.deathDate"), value: "deathDate" },
                { text: "", value: "actions" }
            ]
        };
    },
    methods: {
        getData() {
            DeathAnnouncementsApi.Get().then(response => {
                this.deathAnnouncements = response.data.data;
            });
        },
        closeDelete() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
            this.dialogDelete = false;
        },
        closeDialog() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
            this.dialog = false;
        },
        deleteItemConfirm() {
            DeathAnnouncementsApi.Delete(
                this.editedItem.deathAnnouncementGuid
            ).then(response => {
                if (response.data.status == this.responseStatus.ok) {
                    this.$toast.success(
                        this.$t("operationAccomplishedSuccessfully")
                    );
                    this.closeDelete();
                    this.getData();
                } else {
                    this.$toast.error(
                        this.$t("error." + response.data.message)
                    );
                }
            });
        },
        newItem() {
            this.dialog = true;
        },
        editItem(item) {
            this.editedItem = Object.assign({}, item);
            this.editedItem.deathDate = moment(
                this.editedItem.deathDate
            ).format("YYYY-MM-DD");
            this.editedIndex = this.deathAnnouncements.indexOf(item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedItem = Object.assign({}, item);
            this.editedIndex = this.deathAnnouncements.indexOf(item);
            this.dialogDelete = true;
        }
    }
};
</script>
